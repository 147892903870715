import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component'
import Layout from '../components/layout/index.amp';
import CategoryTitle from "../components/helpers/categoryTitle";

const PopularPosts = loadable(() => import("../components/popularPosts/index.amp"));
const ArticlePreview = loadable(() => import("./articlePreview.amp"));
const AdRightSticky = loadable(() => import("../components/ads/adRightSticky"));
const AdLeftSticky = loadable(() => import("../components/ads/adLeftSticky"));

const Homepage = props => {
    const displayedArticles = [];

    const excludeAlreadyDisplayed = (articles) => {
        return articles.filter(article => !displayedArticles.includes(article.id));
    }
    return (
        <Layout>
            <main className={"wrapper wrapper--default wrapper--article"}>
                <AdLeftSticky/>
                <div className={"container container-grid-unwrap index-top-level-container"}>
                    <div className="left-container">
                        <div className="container container--halves-uneven container--lined top">
                            <div className="container-grid">
                                <div className="container-grid__item">
                                    {
                                        props.data.datoCMS.topLeft.map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="bottom-border" key={article.slug} data={article}
                                                noImage={true}/>
                                        })
                                    }
                                </div>
                                <div className="container-grid__item">
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.topRight).slice(0, 1).map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={article.slug}
                                                                   customClass={"big-heading"}
                                                                   imageMeta={{'max-w': 850, 'q': 20, 'fit': 'crop'}}
                                                                   lazyLoad={false}
                                                                   data={article}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container container--thirds container--lined center">
                            <CategoryTitle link="/finance" title="Finance"/>
                            <div className={"container-grid"}>
                                {
                                    excludeAlreadyDisplayed(props.data.datoCMS.center).slice(0, 3).map((article) => {
                                        displayedArticles.push(article.id);
                                        return <div className={"container-grid__item"} key={article.slug}>
                                            <ArticlePreview
                                                data={article}
                                                noFlag={true}
                                                imageMeta={{width: 300}}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="container container--halves-uneven container--lined second-bottom">
                            <div className="container-grid">
                                <div className="container-grid__item">
                                    <CategoryTitle link="/culture" title="Culture"/>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottomLeft).slice(0, 1).map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="big-heading" key={article.slug} noFlag={true}
                                                data={article} imageMeta={{width: 768}}/>
                                        })
                                    }
                                </div>
                                <div className="container-grid__item">
                                    <CategoryTitle link="/lifestyle" title="LIFESTYLE"/>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottomRight).slice(0, 2).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="bottom-border" key={article.slug} noFlag={true}
                                                data={article}
                                                noImage={index !== 0}
                                                imageMeta={{width: 768}}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container container--thirds container--lined bottom">
                            <CategoryTitle link="/health-wellbeing" title="HEALTH & WELLBEING"/>
                            <div className="container-grid">
                                <div className={"container-grid__item first"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 1).map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={article.slug}
                                                                   data={article}
                                                                   noFlag={true}
                                                                   imageMeta={{width: 300}}/>
                                        })
                                    }
                                </div>
                                <div className={"container-grid__item"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 2).map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={article.slug}
                                                                   data={article}
                                                                   customClass="teaser-card--right-aligned-img"
                                                                   noFlag={true}
                                                                   wrappedImg={true}
                                                                   imageMeta={{width: 300}}/>
                                        })
                                    }
                                </div>
                                <div className={"container-grid__item"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 2).map((article) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={article.slug}
                                                                   data={article}
                                                                   customClass="teaser-card--right-aligned-img"
                                                                   noFlag={true}
                                                                   wrappedImg={true}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopularPosts/>
                </div>
                <AdRightSticky/>
            </main>
        </Layout>
    )
}

// get all publications that equal(eq) the "regular" content-type
export const query = graphql`
  query indexPage($top: [DatoCMS_ItemId], $center: [DatoCMS_ItemId], $bottomLeft: [DatoCMS_ItemId], $bottomRight: [DatoCMS_ItemId], $bottom: [DatoCMS_ItemId]) {
    datoCMS {
      topLeft: allArticles(filter: {category: {anyIn: $top}}, first: "3", skip: "1", orderBy: publishedAt_DESC) {
        ...ArticleFields
      }
      topRight: allArticles(filter: {category: {anyIn: $top}}, first: "4", orderBy: publishedAt_DESC) {
        ...ArticleFieldsWithBigImage
      }
      center: allArticles(filter: {category: {anyIn: $center}}, first: "10", orderBy: publishedAt_DESC) {
        ...ArticleFields
      }
      bottomLeft: allArticles(filter: {category: {anyIn: $bottomLeft}}, first: "11", orderBy: publishedAt_DESC) {
        ...ArticleFieldsWithBigImage
      }
      bottomRight: allArticles(filter: {category: {anyIn: $bottomRight}}, first: "14", orderBy: publishedAt_DESC) {
        ...ArticleFields
      }
      bottom: allArticles(filter: {category: {anyIn: $bottom}}, first: "20", orderBy: publishedAt_DESC) {
        ...ArticleFields
      }
    }
  }
`

export default Homepage
